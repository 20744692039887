<template>
  <div>
    <span
      ><img style="width: 100%" src="../assets/image/home/banner.jpg"
    /></span>
  </div>
  <div class="link-div">
    <span style="font-size: 13px">您当前的位置：</span>
    <el-link @click="toIndex">首页</el-link>
    <span style="font-size: 13px">&nbsp;>&nbsp;</span>
    <el-link @click="toCurrent">关于我们</el-link>
    <p class="link-p"></p>
  </div>
  <div class="about-us">
    <h2>速融科技--高效收银、便捷支付</h2>
    <div>
      <p>
        速融科技商贸有限公司投资创建于2018年。是一家以软件开发、技术推广、技术咨询、支付系统为主、POS机收单业务为辅的网络科技公司。针对不同的客户群体，将以专业化的技术服务团队为客户提供最满意的解决方案，并以“优质、贴心、快速、高效”的服务理念全心全意为客户提供技术支持及售后服务。<br />
      </p>
    </div>
    <div>
      <p>公司立足于山东梁山，业务范围辐射全国各地。</p>
    </div>
    <div>
      <p>
        公司产品畅立收，为全国线上、线下商户提供安全、专业、便捷的收单软硬件产品。
      </p>
    </div>
  </div>
  <div class="about-us">
    <h2>速融科技—让支付更简单</h2>
    <div>
      <p>
        刷脸支付商用元年，刷脸支付AI新科技，打造高效、智能化收银管理平台，引领支付行业新方向。
      </p>
      <div>
        <p>新支付、新科技、新零售、新未来，抓住趋势用智慧营造赋能未来。</p>
      </div>
    </div>
    <div class="pay-times">
      <p>支付1.0时代——现金支付</p>
      <p>支付2.0时代——刷卡支付</p>
      <p>支付3.0时代——扫码支付</p>
      <p>支付4.0时代——刷脸支付</p>
    </div>
    <div style="margin-bottom:50px">
      <p>
        2019年，产业迎来新革命，支付4.0时代正式开启，未来10年都是刷脸支付的时代。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  components: {
    // HelloWorld,
  },

  data() {},
  methods: {
    toIndex() {
      this.$router.push({
        path: "/index",
      });
    },
    toCurrent() {
      this.$router.push({
        path: "/about",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/index.css";
.about-us {
  text-align: left;
  margin-top: 50px;
  padding-left: 270px;
  padding-right: 270px;
}

.about-us p {
  line-height: 30px;
}
.el-link {
  color: #000;
  margin-top: -3px;
}
.link-p {
  border-bottom: 1px solid #bbbbbdec;
}
.link-div {
  padding-left: 270px;
  text-align: left;
  margin-top: 30px;
  padding-right: 270px;
}
.pay-times {
  font-size: 15px;
  margin-bottom: 50px;
  margin-top: 30px;
}
.pay-times p{
  margin-bottom: -8px
}
</style>